import { Button, Form, Modal, notification, Typography } from "antd";
import React, { useState, useEffect } from "react";
import FormCheckbox from "../../components/FormCheckbox/FormCheckbox";
import FormInput from "../../components/FormInput/FormInput";
import {
  NUMBER_REGEX,
  PHONE_NUMBER_REGEX,
  STRING_ONLY,
} from "../../constants/AppConstants";
import dayjs from "dayjs";
import API_SERVICE from "../../services/api-service";
import { stages } from "../complementor-widget/ComplementorWidget";
import { getEnumList, isRazorPay, updateDropOfStageBuyLead } from "../../services/Utility";
import "../BuyLeads/BuyLeads.scss";
import { loanTypeList } from "../start/start";
import { useParams } from "react-router-dom";
import ReCAPTCHA from 'react-google-recaptcha';
import moment from "moment"
import FormSelect from "../../components/FormSelect/FormSelect";
const { Text, Link, Paragraph } = Typography;

interface IBuyLogin {
  getAppContent: any;
  setLeadDetail: Function;
  setTmpLoanKey: any;
  tmpLoanKey: string;
  getNextTask: Function;
  getNextTaskV2: Function;
  isV2: boolean;
  setIsV2: Function;
  setSelectedStageName: React.Dispatch<React.SetStateAction<stages>>;
  loanType: string;
  complementorUserId: String;
  connectorShortDetails: any;
  setShowList: any;
  utmDetailsDto: any;
  uploadLog: (message: string, params: any) => void;
}

const BuyLogin: React.FunctionComponent<IBuyLogin> = (props: IBuyLogin) => {
  const {
    getAppContent,
    setLeadDetail,
    getNextTask,
    setTmpLoanKey,
    tmpLoanKey,
    getNextTaskV2,
    setSelectedStageName,
    loanType,
    isV2,
    setIsV2,
    complementorUserId,
    connectorShortDetails,
    setShowList,
    utmDetailsDto,
    uploadLog,
  } = props;

  const time = 7 * 2 - 1;
  const [formGetOtp] = Form.useForm() as any;
  const [formOTP] = Form.useForm() as any;
  const [loading, setLoading] = useState(false);
  const [loadingGetOTP, setLoadingGetOTP] = useState(false);
  const [verificationId, setVerificationId] = useState("");
  const [isGetOtpDisable, setIsGetOtpDisable] = useState(true);
  const [isNextDisable, setIsNextDisable] = useState(true);
  const [countDown, setCountDown] = React.useState(time);
  const [runTimer, setRunTimer] = React.useState(false);
  const [isTimeStoped, setIsTimeStoped] = useState(false);
  const [ipAddress, setIPAddress] = useState('')
  const [caseId, setCaseId] = useState('')
  const [showCaptchaForConfirmMobile, setShowCaptchaForConfirmMobile] = useState(false);
  const [reCaptchaTokenForConfirmMobile, setReCaptchaTokenForConfirmMobile] = useState("");
  const seconds = String(countDown % 60).padStart(2, "0");
  const minutes = String(Math.floor(countDown / 60)).padStart(2, "0");

  const { type } = useParams() as any;

  useEffect(() => {
    let timerId = null as any;

    if (runTimer) {
      setCountDown(time);
      timerId = setInterval(() => {
        setCountDown((countDown) => countDown - 1);
      }, 1000);
    } else {
      clearInterval(timerId);
    }
    return () => clearInterval(timerId);
  }, [runTimer]);

  useEffect(() => {
    if (countDown === 0 && runTimer) {
      console.log("expired");
      setRunTimer(false);
      setIsTimeStoped(true);
    }
  }, [countDown, runTimer]);
  useEffect(() => {
    formOTP.setFieldsValue({
      serviceProvider: true
    });
    if (connectorShortDetails.mobileNumber) {
      setIsGetOtpDisable(false);
    }
    formGetOtp.setFieldsValue({
      firstName: connectorShortDetails.name,
      mobile: connectorShortDetails.mobileNumber
    })
  }, [connectorShortDetails]);

  useEffect(() => {
    getIPAddress();
  }, []);

  const updateProspect = (prospectId: any) => {
    const user = localStorage.getItem("user");
    // const prospectId = JSON.parse(user as string).prospectId;
    const verificationId = JSON.parse(user as string).id;
    API_SERVICE.updateProspect(prospectId, verificationId)
      .then(({ data }) => { })
      .catch(function (e) { })
      .finally(() => { });
  };

  function checkMobile(mobileNumber, isVerify: boolean = false) {
    disableNext();
    if (mobileNumber?.length === 10) {
      setLoadingGetOTP(true)
      setShowCaptchaForConfirmMobile(false);
      setReCaptchaTokenForConfirmMobile("");
      if (isVerify) {
        setIsGetOtpDisable(true);
        setIsNextDisable(false);
      }
      const payload = {
        mobileNumber: mobileNumber,
        reCaptchaScreenName: "OTP"
      }
      API_SERVICE.checkRecaptchaResponse(payload)
        .then(({ data }) => {
          if (data) {
            setShowCaptchaForConfirmMobile(data?.payload?.captchaRequired);
          }
        })
        .catch(function (e) {
          notification.error({ message: API_SERVICE.handleErrors(e) });
        })
        .finally(() => setLoadingGetOTP(false))
    }
  }

  const addCase = () => {
    setLoading(true);

    let searchParams = new URL(window.location.href).searchParams;
    const urlParams: any = {};
    searchParams?.forEach((value, key) => {
      urlParams[key] = value;
    });

    const consentPayload = {
      ipAddress: ipAddress,
      timeStamp: moment().format("MMM DD, YYYY hh:mm"),
      device: window.navigator.platform,
      tcMessage: "I agree to the T&C, Privacy policy and authorise OneInfinity and or  and hereby authorise OneInfinity and/or its representatives to call,email, Whats APP, or SMS me with reference to my loan application.This consent supersedes any DNC (do not call) registration by me."
    }

    const param = {
      utmDetailsDto: utmDetailsDto,
      overrideSourceConnectorId: urlParams?.sub_connector_id,
      consentPayload: JSON.stringify(consentPayload),
      workFlowType: "BORROWER_DIRECT",
    };

    uploadLog("Add Case Api After Login", param);

    const loanTypeTmp: any =
      (tmpLoanKey?.length ?? 0) > 0 ? tmpLoanKey
        : (loanType?.length ?? 0) > 0
          ? loanType
          : loanTypeList?.find((item: any) => item?.code == type)?.key ??
          "BUSINESS_INSTALLMENT_LOAN";

    API_SERVICE.addCase(loanTypeTmp, complementorUserId, urlParams?.ldata, param)
      .then(({ data }) => {
        if (data) {
          uploadLog("Add Case Api After Login Response", { caseId: data?.payload?.caseDetails?.caseId });
          if (data?.payload) {
            const tmpLeadDetail = data?.payload;
            const prospectId = tmpLeadDetail?.caseDetails?.prospectId ?? "";
            localStorage.setItem("prospectId", prospectId);
            updateProspect(prospectId);
            setLeadDetail(tmpLeadDetail);
            getNextTask(tmpLeadDetail);
            setShowList(false);
          } else {
            setShowList(true);
          }
        }
      })
      .catch(function (e) {
        notification.error({ message: API_SERVICE.handleErrors(e) });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const getLeadList = () => {
    const data = {
      pageNo: 1,
      pageSize: 10,
    };

    const loanTypeTmp: any =
      (tmpLoanKey?.length ?? 0) > 0 ? tmpLoanKey
        : (loanType?.length ?? 0) > 0
          ? loanType
          : loanTypeList?.find((item: any) => item?.code == type)?.key ??
          "BUSINESS_INSTALLMENT_LOAN";

    let searchParams = new URL(window.location.href).searchParams;
    const urlParams: any = {};
    searchParams?.forEach((value, key) => {
      urlParams[key] = value;
    });

    const id = (urlParams?.sub_connector_id?.length ?? 0) > 0 ? urlParams?.sub_connector_id : complementorUserId;
    const extraparam = `&loanProductShortCode=${loanTypeTmp}&sourceConnectorUserId=${id ?? ""}`

    API_SERVICE.getLeadList(formGetOtp.getFieldValue("mobile"), data, extraparam)
      .then(({ data }) => {
        if (
          (data?.payload?.content?.length ?? 0) === 0 &&
          (connectorShortDetails?.processInstanceId?.length ?? 0) > 0 &&
          (connectorShortDetails?.dsaCaseId?.length ?? 0) > 0
        ) {
          const tmpleadDetail = {
            prospectId: connectorShortDetails?.dsaCaseId,
            processInstanceId: connectorShortDetails?.processInstanceId,
          };
          getNextTask(tmpleadDetail);
        } else if (data?.payload?.content?.length > 0) {
          const tmpLeadDetail = data?.payload?.content[0];
          const caseStatus = tmpLeadDetail?.caseDetails?.caseStatus ?? "";
          const prospectId = tmpLeadDetail?.caseDetails?.prospectId ?? "";
          localStorage.setItem("prospectId", prospectId);
          if (
            (data?.payload?.content?.length ?? 0) == 1 &&
            ((caseStatus?.toUpperCase() == "PROSPECT" &&
              !["Education Loan", "Business Installment Loan", "Personal Loan"].includes(tmpLeadDetail?.loanType)) ||
              caseStatus?.toUpperCase() == "LEAD")
          ) {
            const currDropOffStage = tmpLeadDetail?.currDropOffStage ?? "";
            if (currDropOffStage == "OTP_VERIFICATION_PENDING") {
              updateProspect(prospectId);
            }
            let dt = dayjs(
              tmpLeadDetail?.caseDetails?.creationDate,
              "YYYY-MM-DD"
            );
            const diff = dayjs().diff(dt, "day");
            if (diff > 30) {
              setShowList(true);
            } else {
              if (loanType == "HOME_LOAN" || loanType == "EDUCATION_LOAN" || ["Business Installment Loan", "Personal Loan", "Home loan"].includes(tmpLeadDetail?.loanType)) {
                console.log('tmpLeadDetail', tmpLeadDetail);
                setIsV2(tmpLeadDetail?.productVersion == "V2");
                setLeadDetail(tmpLeadDetail);
                getNextTask({ ...tmpLeadDetail, nextTask: tmpLeadDetail?.productVersion != "V2" ? "V1" : "" });
              } else {
                setSelectedStageName("iciciOffer");
              }
            }
          } else {
            setShowList(true);
          }
        } else {
          if (["HOME_LOAN",
            "EDUCATION_LOAN",
            "BUSINESS_INSTALLMENT_LOAN",
            "PERSONAL_LOAN_ONLY",
            "Business Installment Loan",
            "Personal Loan",
            "Home loan"].includes(loanType)) {
            addCase();
          } else {
            setSelectedStageName("iciciOffer");
          }
        }
      })
      .catch(function (e) {
        notification.error({ message: API_SERVICE.handleErrors(e) });
      });
  };

  const verifyOTP = (values: any) => {
    const consentPayload = {
      ipAddress: ipAddress,
      timeStamp: moment().format("MMM DD, YYYY hh:mm"),//moment().locale(“en”).format(“MMM DD, YYYY hh:mm”),
      device: window.navigator.platform,
      tcMessage: "I agree to the T&C, Privacy policy and authorise OneInfinity and or  and hereby authorise OneInfinity and/or its representatives to call,email, Whats APP, or SMS me with reference to my loan application.This consent supersedes any DNC (do not call) registration by me."
      // macAddress: "",
    }
    const data = {
      mobileOtp: values?.otp,
      emailId: "",
      emailOtp: "",
      mobileNumber: formGetOtp.getFieldValue("mobile"),
      verificationId: verificationId,
      caseId: caseId,
      consentPayload: JSON.stringify(consentPayload)
    };

    setLoading(true);
    API_SERVICE.verifyOtp(data)
      .then(({ data }) => {
        const userStr = localStorage.getItem("user");
        const user = JSON.parse(userStr as string);
        const userModel = {
          ...user,
          ...data.payload,
          buyLeadsTransactionId: user?.buyLeadsTransactionId,
          mobile: formGetOtp.getFieldValue("mobile"),
        };
        localStorage.setItem("user", JSON.stringify(userModel));
        if (loanType === "BUY_LEADS") {
          setSelectedStageName("buyLead");
          updateDropOfStageBuyLead("OTP_VERIFIED");
        } else {
          getLeadList();
        }
      })
      .catch((e: any) => {
        notification.error({ message: API_SERVICE.handleErrors(e) });
        checkMobile(formGetOtp.getFieldValue("mobile"), true);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const getIPAddress = async () => {
    // const res = await axios.get("https://api.ipify.org/?format=json");
    // console.log(res.data);
    // setIP(res.data.ip);

    API_SERVICE.getIPData()
      .then(({ data }) => {
        setIPAddress(data.ip);
      })
      .catch((e: any) => {
        notification.error({ message: API_SERVICE.handleErrors(e) });
      })
      .finally(() => { });
  };

  const actionResend = () => {
    if (countDown === 0) {
      if ((showCaptchaForConfirmMobile && reCaptchaTokenForConfirmMobile?.length === 0)) {
        notification.error({
          message: "Please check reCaptcha",
        });
      } else {
        getOtpMobile(formGetOtp.getFieldValue("mobile"));
        checkMobile(formGetOtp.getFieldValue("mobile"));
      }
    }
  };

  const getOtpMobile = (mobile: any) => {

    let searchParams = new URL(window.location.href).searchParams;
    const urlParams: any = {};
    searchParams?.forEach((value, key) => {
      urlParams[key] = value;
    });

    const id = (urlParams?.sub_connector_id?.length ?? 0) > 0 ? urlParams?.sub_connector_id : complementorUserId;
    const extraparam = `&sourceConnectorUserId=${id ?? ""}`

    API_SERVICE.getMobileOtp(mobile, extraparam)
      .then(({ data }) => {
        if (data) {
          notification.success({ message: data?.message });
          setVerificationId(data?.payload?.id);
          setCountDown(time);
          setIsTimeStoped(false);
          setIsGetOtpDisable(true);
          setIsNextDisable(false);
          setRunTimer(true);
        } else {
          notification.error({ message: "no data found" });
        }
      })
      .catch(function (e) {
        notification.error({ message: API_SERVICE.handleErrors(e) });
      });
  };

  const signUp = (values: any) => {
    setLoadingGetOTP(true);

    let searchParams = new URL(window.location.href).searchParams;
    const urlParams: any = {};
    searchParams?.forEach((value, key) => {
      urlParams[key] = value;
    });

    const params = {
      ...values,
      dsaCaseId: connectorShortDetails?.dsaCaseId ?? "",
      processInstanceId: connectorShortDetails?.processInstanceId ?? "",
      overrideSourceConnectorId: urlParams?.sub_connector_id,
      utmDetailsDto: utmDetailsDto
    };

    uploadLog("Signup Api", params);

    const loanTypeTmp: any =
      (tmpLoanKey?.length ?? 0) > 0 ? tmpLoanKey
        : (loanType?.length ?? 0) > 0
          ? loanType
          : loanTypeList?.find((item: any) => item?.code == type)?.key ??
          "BUSINESS_INSTALLMENT_LOAN";

    API_SERVICE.singUp(loanTypeTmp, complementorUserId, params)
      .then(({ data }) => {
        uploadLog("Signup Api Response", data);
        if (data) {
          const user = { ...data.payload, ...values };
          localStorage.setItem("user", JSON.stringify(user));
          setCaseId(data?.payload?.caseId)
          formGetOtp.setFieldsValue({
            mobile: user?.mobile
          });
          notification.success({ message: data.message });
          setVerificationId(data?.payload?.id);
          setIsGetOtpDisable(true);
          setIsNextDisable(false);
          setCountDown(time);
          setRunTimer(true);
        }
      })
      .catch(function (e) {
        notification.error({ message: API_SERVICE.handleErrors(e) });
      })
      .finally(() => {
        setLoadingGetOTP(false);
      });
  };

  const onIndividualType = (value: string) => {
    const payload = {
      "individualType": value
    };
    API_SERVICE.updateLoanProductCode(payload)
      .then(({ data }) => {
        if ((data?.payload?.loanProductShortCode?.length ?? 0) > 0) {
          const loanTypeTmp = loanTypeList?.find((item: any) => item?.code == data?.payload?.loanProductShortCode)?.key;
          setTmpLoanKey(loanTypeTmp);
        } else {
          setTmpLoanKey(null);
        }
        // if ((data?.payload?.loanProductShortCode?.length ?? 0) > 0) {
        //   const loanTypeTmp = loanTypeList?.find((item: any) => item?.code == data?.payload?.loanProductShortCode)?.key;
        //   setLoanType(loanTypeTmp);
        // } else {
        //   const loanTypeTmp = loanTypeList?.find((item: any) => item?.code == type)?.key ??
        //     "BUSINESS_INSTALLMENT_LOAN";
        //   setLoanType(loanTypeTmp);
        // }
      }).catch(function (e) {
        notification.error({ message: API_SERVICE.handleErrors(e) });
      })
  };

  const onFinish = (values: any) => {
    if ((showCaptchaForConfirmMobile && reCaptchaTokenForConfirmMobile.length === 0)) {
      notification.error({
        message: "Please check reCaptcha",
      });
    } else {
      verifyOTP(values);
    }
  };

  const onFinishGetOTP = (values: any) => {
    if ((showCaptchaForConfirmMobile && reCaptchaTokenForConfirmMobile.length === 0)) {
      notification.error({
        message: "Please check reCaptcha",
      });
    } else {
      signUp(values);
      checkMobile(formGetOtp.getFieldValue("mobile"));
    }
  };

  const disableNext = () => {
    formOTP.resetFields(["otp"]);
    setIsNextDisable(true);
    setIsGetOtpDisable(false);
  };

  const renderCheckboxTextIndiaMart = () => {
    return (
      <div style={{ display: "grid" }}>
        <Text>
          I agree to the{" "}
          <Link
            onClick={() => getAppContent("TERMS_AND_CONDITIONS")}
            target="_blank"
          >
            T & C
          </Link>
          ,{" "}
          <Link onClick={() => getAppContent("PRIVACY_POLICY")} target="_blank">
            Privacy policy
          </Link>{" "}
          and authorise OneInfinity representatives to contact me. This consent
          supersedes any DNC registration by me.
        </Text>
      </div>
    );
  };

  const renderCheckboxText = () => {
    return (
      <div style={{ display: "grid" }}>
        <Text>
          I agree to the{" "}
          <Link
            onClick={() => getAppContent("TERMS_AND_CONDITIONS")}
            target="_blank"
          >
            Terms and Conditions
          </Link>
          ,{" "}
          <Link onClick={() => getAppContent("PRIVACY_POLICY")} target="_blank">
            Privacy policy
          </Link>{" "}
          and hereby authorise OneInfinity and/or its representatives to call,
          email, Whats APP, or SMS me with reference to my loan application.
          This consent supersedes any DNC (do not call) registration by me.
        </Text>
        {loanType === "PERSONAL_LOAN_ONLY" && (
          <Text>
            You also authorize our lending partners to retrieve your credit
            report & communicate with you via phone, e-mails, WhatsApp, etc.
          </Text>
        )}
      </div>
    );
  };
  console.log(!runTimer, countDown != 0, showCaptchaForConfirmMobile)
  return (
    <>
      <div style={{ display: "grid", justifyContent: isV2 ? "left" : "center" }}>
        <Form
          layout="vertical"
          autoComplete="off"
          name="leadForm"
          style={{ maxWidth: "400px" }}
          className="form-persion"
          form={formGetOtp}
          onFinish={onFinishGetOTP}
        >
          {type == "pl" && (
            <FormSelect
              isRequired
              name="individualType"
              label="Applicant Type"
              options={getEnumList("INDIVIDUAL_TYPE")}
              onSelect={(e: any) => onIndividualType(e)}
            />
          )}
          <FormInput
            name="firstName"
            label="Your Full Name (As per PAN)"
            isRequired
            onChange={disableNext}
            rules={[
              {
                pattern: STRING_ONLY,
                message: 'No Special Characters "*,),\' etc." Allowed',
              },
            ]}
          />
          {loanType === "BUY_LEADS" && (
            <FormInput
              name="email"
              label="Your Email ID"
              onChange={disableNext}
              isRequired
              rules={[
                { type: "email", message: "Please enter valid Email Id." },
              ]}
            />
          )}
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
              gap: "10px",
            }}
          >
            <div style={{ width: "100%" }}>
              <FormInput
                name="mobile"
                label="Mobile Number"
                inputMode="numeric"
                prefix="+91"
                onChange={(e: any) => { checkMobile(e?.target?.value); setRunTimer(false) }}
                isRequired
                rules={[
                  {
                    pattern: PHONE_NUMBER_REGEX,
                    message: "Please Enter Valid Mobile Number",
                  },
                ]}
              />
            </div>
            <Button
              type="primary"
              className={`otp-btn ${isRazorPay(complementorUserId) ? " RazorPay" : ""}`}
              disabled={isGetOtpDisable}
              htmlType="submit"
              loading={loadingGetOTP}
            >
              Get OTP
            </Button>
          </div>
          {((!runTimer && countDown != 0) && showCaptchaForConfirmMobile) &&
            <div style={{ margin: "auto", display: "table" }}>
              <Form.Item name="reCaptchaToken">
                <ReCAPTCHA
                  sitekey='6LctCysqAAAAAFuSCNIHih9gX7V6yZY6SL4NsFWY' // Replace with your site key
                  onChange={(token) => { setReCaptchaTokenForConfirmMobile(token) }}
                />
              </Form.Item>
            </div>}
        </Form>

        <Form
          layout="vertical"
          autoComplete="off"
          name="leadFormOTP"
          style={{ maxWidth: "400px" }}
          className="form-persion"
          form={formOTP}
          onFinish={onFinish}
        >
          <FormInput
            name="otp"
            type="password"
            label="Enter OTP"
            placeholder="OTP"
            inputMode="numeric"
            isRequired
            isDisabled={isNextDisable}
            rules={[
              {
                pattern: NUMBER_REGEX,
                message: "Please enter valid OTP",
              },
            ]}
          />
          <br />
          {(!isNextDisable && showCaptchaForConfirmMobile) && (
            <div style={{ margin: "auto", display: "table" }}>
              <Form.Item name="reCaptchaToken">
                <ReCAPTCHA
                  sitekey="6LctCysqAAAAAFuSCNIHih9gX7V6yZY6SL4NsFWY" // Replace with your site key
                  onChange={(token) => { setReCaptchaTokenForConfirmMobile(token) }}
                />
              </Form.Item>
            </div>
          )
          }
          {!isNextDisable && (
            <Form.Item>
              Didn't receive the OTP? &nbsp;
              <a onClick={actionResend} style={{ color: "#C71C8E" }}>
                {isTimeStoped ? "RESEND OTP" : `${minutes}:${seconds}`}

                <br />
              </a>
            </Form.Item>
          )}

          <FormCheckbox
            name={"serviceProvider"}
            isRequired
            msgRequired="Terms and Conditions & Privacy policy"
            textElement={
              complementorUserId == "CONN4128778344"
                ? renderCheckboxTextIndiaMart()
                : renderCheckboxText()
            }
          />
          <Button
            type="primary"
            htmlType="submit"
            size="large"
            className={`btn-next ${isRazorPay(complementorUserId) ? " RazorPay" : ""}`}
            loading={loading}
            disabled={isNextDisable}
          >
            Next
          </Button>
        </Form>
      </div>
    </>
  );
};

export default BuyLogin;
